<template>
  <div>
    <page-title />
    <b-alert
      :show="!!error"
      variant="danger"
      v-text="error"
    />
    <b-card>
      <b-form @submit.prevent="login()">
        <b-form-group>
          <b-form-input
            v-model="form.login"
            :state="inputState"
            :disabled="loading"
            required
            placeholder="Login"
            autofocus
          />
        </b-form-group>
        <b-form-group>
          <b-form-input
            v-model="form.password"
            :state="inputState"
            :disabled="loading"
            required
            type="password"
            placeholder="Password"
          />
        </b-form-group>

        <b-button
          type="submit"
          variant="primary"
          :disabled="!canSubmit"
        >
          <b-spinner
            v-if="loading"
            small
          />
          Login
        </b-button>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import { getRequestErrorText } from '@/utils'
import PageTitle from '@/components/PageTitle'

export default {
  name: 'Login',
  components: { PageTitle },
  data () {
    return {
      form: {
        login: '',
        password: ''
      },
      loading: false,
      error: ''
    }
  },
  computed: {
    canSubmit () {
      return this.form.login && this.form.password && !this.loading
    },
    inputState () {
      return this.error ? false : null
    }
  },
  watch: {
    form: {
      handler () {
        this.error = ''
      },
      deep: true
    }
  },
  methods: {
    login () {
      this.error = ''
      this.loading = true

      this.$auth.login({ data: this.form, staySignedIn: true })
        .then(() => {
          this.$store.dispatch('initWs', this.$auth.user().wsToken)
        })
        .catch(error => {
          this.error = getRequestErrorText(error, 'Login failed')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
